body{
    height: auto;
}
.registration .signin-wrapper {
    width: 600px;
}
.recurly-element{
    height: 3em;
}
.text-bold{
    font-weight: 600;
}
.hideform{
    display: none;
}